// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // 引入 router 实例
import plugin from './plugin'; // 引入插件
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'


const app = createApp(App);

app.use(plugin); // 使用插件
app.use(router); // 使用 router
app.use(ViewUIPlus)
app.mount('#app');