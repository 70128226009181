import { createRouter, createWebHistory } from 'vue-router';
import Layout from '@/layout/layout.vue'
import Index from '@/pages/index.vue'
import About from '@/pages/about.vue'
import Solution from '@/pages/solution.vue'
import Test from '@/pages/test.vue'
// 定义路由
const routes = [
    // ... 定义你的路由规则
    {
        path: '/',
        name: 'Layout',
        component: Layout,
        children: [
            {
            path: '/index',
            name: Index.name,
            meta: {
                title: '首页'
            },
            component: Index
           },
            {
                path: '/solution',
                name: Solution.name,
                meta: {
                    title: '解决方案'
                },
                component: Solution
            },
            {
                path: '/about',
                name: About.name,
                meta: {
                    title: '关于'
                },
                component: About
            },
            {
                path: '/test',
                name: Test.name,
                meta: {
                    title: '关于'
                },
                component: Test
            }
            ]
    }
];

// 创建 router 实例
const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;