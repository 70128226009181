<style>
.imgobj {
  height: 60vh;
  width:100vw;
  margin: 0;
  padding: 0;
  display:block;
  object-fit: cover;




}
.imgcontainer {
  padding: 0px;
  margin: 0px;
  height: 60vh;
  width:100vw;

}
.textzone {
  margin: 0;
  padding: 0;
  position: relative;
  top: -45vh;
  left:8vw;
  text-align: left;
  color: white;




}
.textzonetext {
  text-align: left;
  margin: 5px 0;
  font-size: xxx-large;
  font-weight: bolder;

}
.textzonetextsmall {
  font-size: x-large;


}
.textzonecircle {
  display: flex;

}
.textzonecircle div {
  display: flex;
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  background-color: white;
  color: black;
  margin-top: 1vw;
  align-self: center;
  justify-content: center;
  padding-top: 2vw;
  font-weight: bold;


}
.textzonerec {
  margin-top: 2vh;
  width:20vw;
  height: 15vh;
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 7vh;
}
</style>
<template>
  <div class="imgcontainer">
    <img :src="imageSrc20" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">提供全网平台解决方案</div>
      <div class="textzonetextsmall">聚集三十多个主流行业，云集上千个全案解决方案实践</div>
      <div class="textzonetextsmall">只为更好的服务与您的项目</div>

    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc18" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">移动电商</div>
      <div class="textzonetextsmall">适用于O2O、B2B、C2C、分销和B2B2C电子商务模式</div>
      <div class="textzonetextsmall">定制APP开发、支持IOS/安卓双系统</div>
      <div class="textzonecircle">
        <div>APP</div>
        <div style="margin-left: 2vw">会员管理</div>
        <div style="margin-left: 2vw">订单管理</div>
        <div style="margin-left: 2vw">加盟店管理</div>
        <div style="margin-left: 2vw">统计报表</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc11" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">智慧社区解决方案</div>
      <div class="textzonetextsmall">智慧社区解决方案为用户提供社交移动服务的客户端，通过社交服务平台与朋友保</div>
      <div class="textzonetextsmall">持了更加直接的联系，建立大的交际圈，满足用户的交流需求。其不仅起到巩固老</div>
      <div class="textzonetextsmall">朋友关系的需求，还起到挖掘新朋友的作用，让交友突破时间、空间的限制</div>
      <div class="textzonecircle">
        <div>第三方支付</div>
        <div style="margin-left: 2vw">第三方支付</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc13" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">智慧城市</div>
      <div class="textzonetextsmall">为方便用户随时了解房源信息、预约看房、查询房价信息和楼盘的周边环境，房地</div>
      <div class="textzonetextsmall">产APP应用可以满足有意购房者的需求，是房地场移动营销的载体，进一步实现低</div>
      <div class="textzonetextsmall">成本、高效率的营销效果，成为传统企业转型的利器</div>
      <div class="textzonecircle">
        <div>智慧政府</div>
        <div style="margin-left: 2vw">智能运营</div>
        <div style="margin-left: 2vw">智能交通</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc9" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">社交类</div>
      <div class="textzonetextsmall">支持所有IM场景，沟通联系无需顾忌</div>
      <div class="textzonetextsmall">定制APP开发、支持IOS/安卓双系统</div>
      <div class="textzonetextsmall">支持商家端/客户端 双端开发</div>
      <div class="textzonetextsmall">支持APP+PC+web+微信四合一全网开发</div>
      <div class="textzonecircle">
        <div>互动游戏</div>
        <div style="margin-left: 2vw">视频直播</div>
        <div style="margin-left: 2vw">任务发布</div>
        <div style="margin-left: 2vw">语音聊天</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc27" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">政务类</div>
      <div class="textzonetextsmall">它不仅可以为各个部门间提供一个信息网络交流、资源共享的平台，而且还提高政</div>
      <div class="textzonetextsmall">府部门 间协调的能力和工作效率，并可将可公开的信息向社会开放，提高了政府工</div>
      <div class="textzonetextsmall">作的透明度，还促进了政府部门与社会各界特别是与人民群众的沟通，提高了办事</div>
      <div class="textzonetextsmall">效率，达到互联网+政务服务</div>
      <div class="textzonecircle">
        <div>办事指南</div>
        <div style="margin-left: 2vw">政民互动</div>
        <div style="margin-left: 2vw">经费审批</div>
        <div style="margin-left: 2vw">OA办公</div>
        <div style="margin-left: 2vw">综合管理</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc8" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">旅游类</div>
      <div class="textzonetextsmall">布局清晰、操作建档、手指一点、出行无忧</div>
      <div class="textzonetextsmall">定制APP开发、支持IOS/安卓双系统</div>
      <div class="textzonetextsmall">支持商家端/客户端 双端开发</div>
      <div class="textzonetextsmall">支持APP+PC+web+微信四合一全网开发</div>
      <div class="textzonecircle">
        <div>酒店预定</div>
        <div style="margin-left: 2vw">机票预定</div>
        <div style="margin-left: 2vw">周边旅游</div>
        <div style="margin-left: 2vw">旅游计划</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc18" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">医疗类</div>
      <div class="textzonetextsmall">提高工作效率、降低差错风险、软化医疗关系</div>
      <div class="textzonetextsmall">支持商家端/客户端 双端开发</div>
      <div class="textzonetextsmall">支持APP+PC+web+微信四合一全网开发</div>
      <div class="textzonecircle">
        <div>缴费记录</div>
        <div style="margin-left: 2vw">病例文档</div>
        <div style="margin-left: 2vw">专家预约</div>
        <div style="margin-left: 2vw">报告查询</div>
      </div>
    </div>
  </div>

  <div class="imgcontainer">
    <img :src="imageSrc6" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">地产类</div>
      <div class="textzonetextsmall">借助互联网，进军房地产！买房卖房租房，一站搞定，随时随地，享尽先机</div>

      <div class="textzonecircle">
        <div>经纪人管理</div>
        <div style="margin-left: 2vw">行价盘搜索</div>
        <div style="margin-left: 2vw">房源发布</div>
        <div style="margin-left: 2vw">预约看房</div>
        <div style="margin-left: 2vw">沙盘演示</div>
      </div>
    </div>

  </div>
  <div class="imgcontainer">
    <img :src="imageSrc22" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">行业门户类</div>
      <div class="textzonetextsmall">移动网路大众，门户更显不同</div>
      <div class="textzonetextsmall">定制APP开发、支持IOS/安卓双系统</div>
      <div class="textzonetextsmall">管理端/企业端/客户端 双端开发</div>
      <div class="textzonetextsmall">支持APP+PC+web+微信四合一全网开发</div>
      <div class="textzonecircle">
        <div>广告管理</div>
        <div style="margin-left: 2vw">资讯分类</div>
        <div style="margin-left: 2vw">信息系统</div>
        <div style="margin-left: 2vw">人才招聘</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc15" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">在线教育类</div>
      <div class="textzonetextsmall">教育移动解决方案基于行业数字化、移动信息化的转型潮流，覆盖教育机构、教</div>
      <div class="textzonetextsmall">师、家长、学生四个用户层面，将学校教育、家庭教育、社会教育结合起来，提高</div>
      <div class="textzonetextsmall">了教育服务和管理的效率，提升了整个教育行业的服务品质</div>
      <div class="textzonecircle">
        <div>云课堂</div>
        <div style="margin-left: 2vw">在线考试</div>
        <div style="margin-left: 2vw">在线直播</div>
        <div style="margin-left: 2vw">云课堂</div>
      </div>
    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc10" class="imgobj" />
    <div class="textzone">
      <div class="textzonetext">餐饮类</div>
      <div class="textzonetextsmall">外卖大战不用“方”，我们来帮忙，助您圆满完成送餐任务</div>
      <div class="textzonetextsmall">定制APP开发、支持IOS/安卓双系统</div>
      <div class="textzonetextsmall">管理端/企业端/客户端 双端开发</div>
      <div class="textzonetextsmall">支持APP+PC+web+微信四合一全网开发</div>
      <div class="textzonecircle">
        <div>智慧政府</div>
        <div style="margin-left: 2vw">餐厅加盟</div>
        <div style="margin-left: 2vw">在线点餐</div>
        <div style="margin-left: 2vw">积分系统</div>
        <div style="margin-left: 2vw">顾客点评</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {

      imageSrc20: require('@/assets/p20.png'),
      imageSrc28: require('@/assets/p28.jpg'),
      imageSrc11: require('@/assets/p11.jpg'),
      imageSrc13: require('@/assets/p13.jpg'),

      imageSrc9: require('@/assets/p9.jpg'),
      imageSrc27: require('@/assets/p27.jpg'),
      imageSrc8: require('@/assets/p8.jpg'),
      imageSrc18: require('@/assets/p18.jpg'),

      imageSrc6: require('@/assets/p6.jpg'),
      imageSrc22: require('@/assets/p22.jpg'),
      imageSrc15: require('@/assets/p15.jpg'),
      imageSrc10: require('@/assets/p10.jpg')


    }
  }
}
</script>

