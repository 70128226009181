<style scoped>
.container {
  height: 240vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

.box {
  width: 100%;
  position: absolute;
  left: 0;
  top: -60vh; /* Initially positioned above the container */
  transition: top 0.3s; /* Smooth transition */
}
.boxheight {
  height:60vh
}
</style>

<template>
  <div class="container">
    <div  class="boxheight" id="box1" style="background-color: red">1</div>
    <div class="boxheight" id="box2" style="background-color: yellow">2</div>
    <div class="box boxheight" id="box3" style="background-color: blue">3</div>
    <div class="box boxheight" id="box4" style="background-color: green">4</div>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  mounted() {
    const box1 = document.getElementById('box1');
    const box2 = document.getElementById('box2');
    const box3 = document.getElementById('box3');
    const box4 = document.getElementById('box4');

    const container = document.querySelector('.content');

    container.addEventListener('scroll', function() {

      const scrollTop = container.scrollTop;
      box1.style.top = scrollTop < 100 ? '0' : '-100px';
      box2.style.top = scrollTop < 200 ? '100px' : '-100px';
      box3.style.top = scrollTop < 300 ? '200px' : '-100px';
      box4.style.top = scrollTop < 400 ? '300px' : '-100px';
    });
  },
  components: {


  },


  methods: {


  }

}
</script>

