<style scoped>
.custom-header {
  background-color: #27598A !important;
  height: 8vh !important;
}
.headermenue {
  background-color: #27598A !important;
  height: 6vh !important;
  padding-top:1vh !important;

}
.content {
  height: 92vh !important;

  overflow-y: auto;
  overflow-x:hidden;
}
.footer {
  background-image: linear-gradient(to right,  whitesmoke, #27598A) !important;
  height: 5vh !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 1vh !important;

}
.footerbar {
  height: 20vh !important;
  display: flex;
  justify-content: space-around;
  text-align: left;
  margin-bottom: 2vh !important;
}
.footerimg {
  object-fit: cover;
  margin: 0;
  margin-bottom: 2vh !important;
  height: 50vh;
  width: 100vw;

}
.footerbartext {
  width:15vw;
  font-weight:bolder;
  font-size: larger !important;
  margin: 10px 0 !important;
  padding: 5px 0 !important;
  border-bottom: 1px solid darkgray !important;
}
.layout{
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  height: 100vh !important;
}
.layout-logo{
  /* width: 140px;*/
  object-fit: cover;
  height: 60px;
  text-align: center;

  border-radius: 3px;
  float: left;
  position: relative;

  left: 15px;
}
.layout-nav{
  width: 420px;
  margin: 0 auto;
  margin-right: 20px;
}
.layout-footer-center{
  text-align: center;
}
.textzone {
  position: relative;

  float: left;
  top: -45vh;
  left:8vw;
  color: white;

}
.textzonetext {
  text-align: left;
  margin: 5px 0;
  font-size: xxx-large;
  font-weight: bolder;

}
.textzonecircle {
  display: flex;

}
.textzonecircle div {
  display: flex;
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  background-color: white;
  color: black;
  margin-top: 1vw;
  align-self: center;
  justify-content: center;
  padding-top: 2vw;
  font-weight: bold;


}
.imgcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;

}
.imgobj {
  display:block;
  border-radius: 20px;
  width: 98vw;
  object-fit: cover;
}
.dev-run-preview .dev-run-preview-edit{ display: none }
</style>
<template>

  <div class="layout">
    <Layout>
      <Header  class="custom-header">
        <Menu class="headermenue"  mode="horizontal" theme="dark" active-name="1">

          <img :src="imageSrc" class="layout-logo" >

          <div class="layout-nav">
            <MenuItem name="服务" @click="gopage(2)">
              <Icon type="ios-navigate"></Icon>
              解决方案
            </MenuItem>
            <MenuItem name="2" @click="gopage(1)">
              <Icon type="ios-keypad"></Icon>
              服务
            </MenuItem>
            <MenuItem name="3" @click="gopage(3)">
              <Icon type="ios-analytics"></Icon>
              关于我们
            </MenuItem>
            <!--          <MenuItem name="3" @click="gopage(4)">-->
            <!--            <Icon type="ios-analytics"></Icon>-->
            <!--            test-->
            <!--          </MenuItem>-->
          </div>
        </Menu>
      </Header>
      <Content class="content" id="contentpart">
        <div>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </div>
        <div>
          <div>
<!--            <div class="imgcontainer">-->
<!--              <img :src="imagechart" class="imgobj" />-->


<!--            </div>-->
          </div>
          <div class="footerbar">
            <div>
              <div class="footerbartext">服务</div>
              <div>网站建设</div>
              <div>软件开发</div>
              <div>APP开发</div>
              <div>微信小程序开发</div>
              <div>网络运营</div>
              <div>互联网运营</div>
            </div>
            <div>
              <div class="footerbartext">解决方案</div>
              <div>电商类</div>
              <div>社区类</div>
              <div>智慧城市类</div>
              <div>家政类</div>
              <div>政务类</div>
              <div>新媒体咨询类</div>
            </div>
            <div>
              <div class="footerbartext">关于我们</div>
              <div>解决方案</div>
              <div>网站建设</div>
              <div>软件开发</div>
              <div>APP开发</div>
              <div>微信小程序开发</div>

            </div>
            <div>
              <div class="footerbartext">联系我们</div>
              <div>邮箱：</div>
              <div>地址：</div>
              <div>电话：</div>

            </div>
          </div>
          <div>
            <Footer class="layout-footer-center footer">
              Copyright@ 2016-2024 腾骧趋势(北京)科技有限公司 teng-shang.com 京ICP备16037586号
            </Footer>
          </div>
        </div>

      </Content>


    </Layout>
  </div>
</template>
<script>
import {Image} from "view-ui-plus";

export default {
  data(){
    return {
      imagechart: require('@/assets/WEB-05.png'),
      imageSrc: require('@/assets/logo.png')
    }
  },
  components:{Image},

  created(){},
  mounted(){},
  methods:{
    gopage:function(page){
      if(page==1) {
        this.$router.push('/index')
      }
      if(page==2) {
        this.$router.push('/solution')
      }
      if(page==3) {
        this.$router.push('/about')
      }
      if(page==4) {
        this.$router.push('/test')
      }
    }

  }



}
</script>

