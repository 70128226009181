<style>
.imgobj {
  height: 60vh;
  width:100vw;
  margin: 0;
  padding: 0;
  display:block;
  object-fit: cover;




}
.imgcontainer {

  padding: 0px;
  margin: 0px;
  height: 60vh;
  width:100vw;

}
.textzone {
  margin: 0;
  padding: 0;
  position: relative;
  top: -45vh;
  left:8vw;
  text-align: left;
  color: white;




}
.textzonetext {
  text-align: left;
  margin: 5px 0;
  font-size: xxx-large;
  font-weight: bolder;

}
.textzonetextsmall {
  font-size: x-large;


}
.textzonecircle {
  display: flex;

}
.textzonecircle div {
  display: flex;
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  background-color: white;
  color: black;
  margin-top: 1vw;
  align-self: center;
  justify-content: center;
  padding-top: 2vw;
  font-weight: bold;


}
.textzonerec {
  margin-top: 2vh;
  width:20vw;
  height: 15vh;
  background-color: white;
  color: black;
  text-align: center;
  padding-top: 7vh;
}
.items {
  position: relative;float: left;z-index: 100;margin-top: -10vh; width: 100% ;color: white;display: flex;
  justify-content:space-evenly;
  background-color: rgba(255, 255, 255, 0);
  height: 30vh;

}
.items .bar {
  padding: 1vw;
  height: 30vh;


}
.items .itemstitle {
  width: 12vw;
  font-size: x-large;
  font-weight: bolder;

}
.items .itemscontent {
  width: 12vw;
  text-align: left;
  font-size: large;
  padding: 1vw;
}
.bg1 {
  background-image: url('@/assets/p14.jpg');
}
.bg2 {
  background-image: url('@/assets/p16.jpg');
}
.bg3 {
  background-image: url('@/assets/p19.jpg');
}

</style>
<template>
  <div class="imgcontainer">
    <img :src="imageSrc26" class="imgobj" />
    <div class="textzone" >
      <div class="textzonetext">与远见者前行</div>
      <div class="textzonetextsmall">腾骧趋势是一家专注于APP、小程序、网站、软件开发等的科技企业</div>
      <div class="textzonetextsmall">常年服务于知名国企及上市企业，其中120余家上市企业，为企业探寻互联网领域的</div>
      <div class="textzonetextsmall">转变与机会，以创新的方式改善企业产品、服务、流程及体验，提供跨平台、媒体</div>
      <div class="textzonetextsmall">和技术的综合解决方案，创造富有影响力的商业及管理价值</div>

    </div>
  </div>
  <div class="imgcontainer"  style="height: 80vh">
    <div style="font-size: xx-large;margin: 2vh 0;height:15vh">
      <div>我们致力于用优质的用户体验及科技实力</div>
      <div>保障项目质量用创新变革带来</div>
      <div>更好的产品服务体验</div>
    </div>
    <img :src="imageSrc29" class="imgobj" style="height: 45vh" />
    <div  class="items">
       <div class="bar bg1">
         <div class="itemstitle">设计优势</div>
         <div class="itemscontent">设计师多毕业于知名美院，丰富设计经验，精通平面设计与交互设计， 服务多个知名客户</div>
       </div>
       <div  class="bar bg2">
         <div class="itemstitle">技术优势</div>
         <div class="itemscontent">深耕多年，资历丰厚，多项专利成果，响应迅速，完整业务服务支持</div>
       </div>
       <div  class="bar bg3">
         <div class="itemstitle">售后服务</div>
         <div class="itemscontent">存在的BUG提供终身免费修复，提供远程／现场配套支持，多种定制型服务类型，升级维护合二为一</div>
       </div>
    </div>

  </div>
  <div style="font-size: xx-large;margin: 3vh 0">光而不耀,和而不同</div>
  <div class="imgcontainer">
    <img :src="imageSrc1" class="imgobj" />
    <div class="textzone" style="margin-top: 18vh" >
      <div class="textzonetext">务实</div>
      <div class="textzonetextsmall">我们将一切付诸行动——只做自己相信的事，并通过愿景的实现来建立信任。在围绕</div>
      <div class="textzonetextsmall">明确目标快速行动的同时，我们也积极预测用户的需求，为我们创造新的商业机遇</div>

    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc17" class="imgobj" />
    <div class="textzone" style="margin-top: 18vh" >
      <div class="textzonetext">远见</div>
      <div class="textzonetextsmall">敢于探索未知的道路——哪里能为用户创造价值，我们就在哪里实现颠覆。同时，我</div>
      <div class="textzonetextsmall">们睿智地考虑问题的多元性，以卓尔不凡的思维和行动方式迎接挑战</div>

    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc21" class="imgobj" />
    <div class="textzone" style="margin-top: 18vh" >
      <div class="textzonetext">真诚</div>
      <div class="textzonetextsmall">我们重视每一个行为的真实性、一致性和透明性，坦诚而真挚地同利益相关方分享我</div>
      <div class="textzonetextsmall">们的历程与行动</div>

    </div>
  </div>
  <div class="imgcontainer">
    <img :src="imageSrc31" class="imgobj" />
    <div class="textzone" style="margin-top: 18vh" >
      <div class="textzonetext">热情</div>
      <div class="textzonetextsmall">我们对使命充满热忱，旨在建立坚韧、自然和持久的合作关系，希望这种火花能散播</div>
      <div class="textzonetextsmall">到身边的每个人，与我们共享这段旅程</div>

    </div>

  </div>


</template>
<script>
export default {
  data(){
    return {

      imageSrc26: require('@/assets/p26.png'),
      imageSrc29: require('@/assets/p29.jpg'),
      imageSrc1: require('@/assets/p1.jpg'),
      imageSrc17: require('@/assets/p17.jpg'),
      imageSrc21: require('@/assets/p21.jpg'),
      imageSrc31: require('@/assets/p31.jpg')


    }
  }
}
</script>

